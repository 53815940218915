import { FC } from 'react';
import { ExclamationCircleIcon, CheckCircleIcon, InformationCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';

type AlertType = {
  type?: 'danger' | 'success' | 'warning' | 'info';
  text: string;
  onClose?: () => void;
};

const Alert: FC<AlertType> = ({ type = 'info', text, onClose }) => {
  // Настройки стилей в зависимости от типа оповещения
  const styles = {
    info: {
      background: 'bg-blue-50',
      text: 'text-blue-800',
      border: 'border-blue-200',
      icon: <InformationCircleIcon className="h-5 w-5 text-blue-500" aria-hidden="true" />
    },
    success: {
      background: 'bg-green-50',
      text: 'text-green-800',
      border: 'border-green-200',
      icon: <CheckCircleIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
    },
    warning: {
      background: 'bg-yellow-50',
      text: 'text-yellow-800',
      border: 'border-yellow-200',
      icon: <ExclamationCircleIcon className="h-5 w-5 text-yellow-500" aria-hidden="true" />
    },
    danger: {
      background: 'bg-red-50',
      text: 'text-red-800',
      border: 'border-red-200',
      icon: <XCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
    }
  };

  const { background, text: textColor, border, icon } = styles[type];

  return (
    <div
      className={`${background} ${textColor} ${border} border rounded-md p-4 mb-4 flex items-start`}
      role="alert"
    >
      <div className="flex-shrink-0 mr-3 mt-0.5">
        {icon}
      </div>
      <div className="flex-1 text-sm">
        {text}
      </div>
      {onClose && (
        <button
          type="button"
          className="ml-auto -mx-1.5 -my-1.5 bg-transparent rounded-lg p-1.5 inline-flex items-center justify-center h-8 w-8 hover:bg-opacity-20 hover:bg-gray-300 focus:outline-none"
          onClick={onClose}
          aria-label="Закрыть"
        >
          <span className="sr-only">Закрыть</span>
          <svg className={`w-4 h-4 ${textColor}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
        </button>
      )}
    </div>
  );
};

export default Alert;
