import { Link } from 'react-router-dom';
import moment from 'moment';
export default function PublicHistoryItem(props: any) {
  return (
    <article
      className="p-4 bg-white max-w-full shadow-emerald-300 
      flex gap-3 items-start flex-col sm:flex-row sm:items-start"
      key={props.history.id}
    >
      <Link
        to={`/profile/${props.personId}/histories/${props.history.id}/`}
        className='min-w-36 h-36 block'
      >
        {
          props.history.photos.length === 0 ?
            <img
              className="w-36 h-36 bg-slate-400 object-cover"
              src={
                props.history.person.avatar
                  ? `${process.env.REACT_APP_PERSONS_SMALL_STATIC}${props.history.person.avatar}`
                  : `${process.env.REACT_APP_COMMON_STATIC}/placeholder.jpg`
              }
              alt={props.history.person.givenNames}
            /> :
            <img
              className="w-36 h-36 bg-slate-400 object-cover"
              src={
                `${process.env.REACT_APP_PERSONS_PHOTO_STATIC}${props.history.photos[0].photo}`
              }
              alt={props.history.person.givenNames}
            />
        }
      </Link>
      <div className='flex-grow h-full flex flex-col justify-between'>
        <div>
          <h2 className="text-xl font-bold tracking-tight text-gray-900 ">
            <Link
              to={`/profile/${props.personId}/histories/${props.history.id}/`}
              className="truncate text-primary-600 "
            >
              {props.history.title.substring(0, 25) || 'Без заголовка'}
            </Link>
          </h2>
          <div className="flex items-center gap-1 mb-2">
            <div>

              <h4 className="text-sm tracking-tight">
                <Link
                  to={`/profile/${props.personId}/histories/${props.history.id}/`}
                  className="truncate  underline"
                >
                  {props.history.person.surname} {props.history.person.givenNames}
                </Link>
              </h4>
            </div>
          </div>
          <p className="mb-3 font-light text-gray-500">
            {props.history.previewText?.substring(0, 100) ||
              `${props.history.detailText?.substring(0, 100)} ...`}
          </p>
        </div>
        <div className="flex justify-end items-center">
          <div className='max-md:hidden text-sm'>
            {moment(props.history.createdAt).format('DD.MM.YYYY')}
          </div>
        </div>
      </div>
    </article>
  );
}
