import { IPerson } from '../../models/IPerson';

interface GridPhotosProps {
  persons: IPerson[];
}

export const GridPersonPhotos = ({ persons }: GridPhotosProps) => {
  const ImgTemplate: any = ({ person }: any) => {
    return (
      <a href={`/beta-tree/${person.id}`} className='flex flex-col justify-center items-center'>
        <img
          src={person.avatar
            ? `${process.env.REACT_APP_PERSONS_SMALL_STATIC}${person.avatar}`
            : `${process.env.REACT_APP_COMMON_STATIC}placeholder.jpg`}
          className="h-auto max-w-full rounded-lg"
          alt={person.givenNames}
        />
      </a>
    )
  }

  const personsTemplate = [];
  let chunk: any[] = [];

  persons.forEach((person: any, index: number) => {
    chunk.push(person);

    if ((index + 1) % 3 === 0) {
      personsTemplate.push(
        <div className="grid gap-4" key={index}>
          {chunk.map((person) => (
            <ImgTemplate key={person.id} person={person}></ImgTemplate>
          ))}
        </div>,
      );

      chunk = [];
    }
  });

  if (chunk.length > 0) {
    personsTemplate.push(
      <div className="grid gap-4" key={persons.length}>
        {chunk.map((person) => (
          <ImgTemplate key={person.id} person={person}></ImgTemplate>
        ))}
      </div>,
    );
  }

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
      {personsTemplate}
    </div>
  )
};