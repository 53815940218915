import PersonService from '../api/PersonService';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTypedSelector } from '../hooks/useTypedSelector';
import { UserGroups } from '../types';
import BetaTreeScreen from '../modules/tree/components/BetaTreeScreen.jsx';

const BetaTree: FC = (props: any) => {
  const [person, setPerson] = useState({} as any);
  const [persons, setPersons] = useState([] as any);
  const { user } = useTypedSelector((state) => state.auth);
  const { id } = useParams();

  const isGuest = UserGroups.guest === user.group;

  useEffect(() => {
    const personId = Number(id);

    if (!personId) {
      console.log('redirect');
    }

    PersonService.getPerson(Number(personId)).then(({ data }) => {
      setPerson(data);
    });
    loadPersons();
  }, [id]);

  const loadPersons = () => {
    PersonService.getSlimList().then(({ data }) => {
      setPersons(data.data);
    });
  }

  if (!person || !persons.length) {
    return <div>404</div>;
  }

  return (
    <div>
      <section className="px-2 py-32 bg-gray-200 md:px-0">
        <div className="container mx-auto">
          <div className="flex flex-wrap justify-between items-center mx-auto flex-col sm:flex-row">
            <div>
              <h1 className=" text-3xl font-extrabold tracking-tight leading-none text-gray-900 md:text-2xl lg:text-3xl">
                Просмотр дерева
              </h1>
              {!isGuest && <a
                href="/cabinet/person"
                className="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 underline text-md"
              >
                Вернуться к списку профилей
              </a>}

            </div>
            {!isGuest &&
              <div className='gap-4 flex items-center '>
                <a
                  type="button"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center  md:mr-0 w-full sm:w-auto"
                  href="/cabinet/person/add"
                >
                  Добавить профиль
                </a>
              </div>
            }
          </div>
          <div className='mt-3'>
            <BetaTreeScreen persons={persons} personId={person?.id} />
          </div>
          <div className='bg-slate-50 rounded-xl w-full text-sm p-4 mt-5'>
            <h1 className='text-gray-600 text-lg font-bold'>Как ориентироваться в дереве</h1>
            <ul className='list-disc pl-5'>
              <li>Визуально сверху вниз идет расположение от родителей к детям;</li>
              <li>На диаграме выведена все семейная линия;</li>
            </ul>
          </div>
        </div>


      </section>
    </div>
  );
};

export default BetaTree;
