import { FC } from 'react';

const Pagination: FC = ({ count = 1, totalPages = 1, page = 1, data, setPage }: any) => {
  const MAX_PAGES = 7;

  const nextPage = () => {
    if (totalPages > Number(page)) {
      setPage(Number(page) + 1);
    }
  };

  const prevPage = () => {
    if (1 < Number(page)) {
      setPage(Number(page) - 1);
    }
  };

  const generatePagination = () => {
    const currentPage = Number(page);

    if (totalPages <= MAX_PAGES) {
      return Array(totalPages)
        .fill('')
        .map((_, index) => index + 1);
    }

    const leftSiblingIndex = Math.max(currentPage - 1, 1);
    const rightSiblingIndex = Math.min(currentPage + 1, totalPages);

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPages - 1;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 5;
      const leftRange = Array(leftItemCount)
        .fill('')
        .map((_, index) => index + 1);

      return [...leftRange, '...', totalPages];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 5;
      const rightRange = Array(rightItemCount)
        .fill('')
        .map((_, index) => totalPages - rightItemCount + index + 1);

      return [1, '...', ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = [leftSiblingIndex, currentPage, rightSiblingIndex];

      return [1, '...', ...middleRange, '...', totalPages];
    }
  };

  const pagesArray = generatePagination();

  const pagesTemplate = pagesArray?.map((pageNum, index) => {
    if (pageNum === '...') {
      return (
        <span
          key={`dots-${index}`}
          className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700"
        >
          ...
        </span>
      );
    }

    return (
      <button
        onClick={() => setPage(pageNum)}
        key={index}
        className={`${pageNum === Number(page)
            ? 'z-10 inline-flex items-center bg-primary-600 text-sm font-medium text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600'
            : 'inline-flex items-center text-sm font-medium text-gray-900 bg-white hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
          } relative rounded-md border border-gray-300 px-4 py-2 mx-1`}
      >
        {pageNum}
      </button>
    );
  });

  if (totalPages <= 1) {
    return null;
  }

  return (
    <div className="flex items-center justify-between px-0 py-4">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          onClick={prevPage}
          disabled={Number(page) === 1}
          className={`relative inline-flex items-center rounded-md px-4 py-2 text-sm font-medium ${Number(page) === 1
              ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
              : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-300'
            }`}
        >
          Назад
        </button>
        <button
          onClick={nextPage}
          disabled={Number(page) === totalPages}
          className={`relative ml-3 inline-flex items-center rounded-md px-4 py-2 text-sm font-medium ${Number(page) === totalPages
              ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
              : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-300'
            }`}
        >
          Далее
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Страница <span className="font-medium">{page}</span> из{' '}
            <span className="font-medium">{totalPages}</span> (всего{' '}
            <span className="font-medium">{count}</span> записей)
          </p>
        </div>
        <div>
          <nav className="isolate inline-flex" aria-label="Pagination">
            <button
              onClick={prevPage}
              disabled={Number(page) === 1}
              className={`relative inline-flex items-center rounded-md px-3 py-2 text-sm font-medium mr-2 ${Number(page) === 1
                  ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-300'
                }`}
            >
              <span className="sr-only">Назад</span>
              <svg
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            {pagesTemplate}
            <button
              onClick={nextPage}
              disabled={Number(page) === totalPages}
              className={`relative inline-flex items-center rounded-md px-3 py-2 text-sm font-medium ml-2 ${Number(page) === totalPages
                  ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-300'
                }`}
            >
              <span className="sr-only">Далее</span>
              <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
