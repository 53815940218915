import { useCallback, useState, useEffect, useMemo } from 'react';
import {
  ReactFlow,
  addEdge,
  ConnectionLineType,
  useNodesState,
  useEdgesState,
  useReactFlow,
  Background,
  Controls,
} from '@xyflow/react';
import { nodeTypes } from './NodeTypes';
import { getLayoutedElements } from './LayoutUtils';
import BaseModal from '../../../common/BaseModal';
import PersonForm from '../../../manager-cabinet/components/persons/PersonForm';

const edgeType = 'smoothstep';

const nodeWidth = 300;
const nodeHeight = 120;

const Flow = ({ persons, personId = 0 }) => {
  const [selectedNodeId, setSelectedNodeId] = useState(personId);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({ personId: null, relation: null });

  const initialEdges = useMemo(
    () =>
      persons.flatMap((item) => {
        const edges = [];
        if (item.parentMaleId) {
          edges.push({
            id: `e${item.parentMaleId}-${item.id}`,
            source: item.parentMaleId.toString(),
            target: item.id.toString(),
            type: edgeType,
            animated: true,
          });
        }
        if (item.parentFemaleId) {
          edges.push({
            id: `e${item.parentFemaleId}-${item.id}`,
            source: item.parentFemaleId.toString(),
            target: item.id.toString(),
            type: edgeType,
            animated: true,
          });
        }
        return edges;
      }),
    [persons],
  );

  const handleAddPerson = useCallback((id, relation) => {
    setModalData({ personId: id, relation });
    setShowModal(true);
  }, []);

  const handleEditPerson = useCallback((id) => {
    setModalData({ personId: id, relation: 'edit' });
    setShowModal(true);
  }, []);

  const initialNodes = useMemo(
    () =>
      persons.map((item) => ({
        id: item.id.toString(),
        type: 'custom',
        data: {
          id: item.id,
          surname: item.surname,
          givenNames: item.givenNames,
          birthAt: item.birthAt,
          deathAt: item.deathAt,
          avatar: item.avatar,
          gender: item.gender,
          onAddPerson: handleAddPerson,
          onEditPerson: handleEditPerson,
        },
        position: { x: 0, y: 0 },
      })),
    [persons, handleAddPerson, handleEditPerson],
  );

  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
    initialNodes,
    initialEdges,
  );

  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);

  const onNodeClick = useCallback((_, node) => {
    setSelectedNodeId(node.id);
  }, []);

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          { ...params, type: ConnectionLineType.SmoothStep, animated: true },
          eds,
        ),
      ),
    [],
  );

  const { setViewport } = useReactFlow();

  const handleTransform = useCallback(() => {
    const node = nodes.find(node => node.id === selectedNodeId.toString());

    if (node) {
      const centerX = node.position.x + nodeWidth / 2;
      const centerY = node.position.y + nodeHeight / 2;

      const windowReactFlowElement = document.getElementsByClassName('react-flow')[0];

      if (!windowReactFlowElement) {
        return;
      }

      setViewport(
        {
          zoom: 1,
          x: windowReactFlowElement.clientWidth / 2 - centerX,
          y: windowReactFlowElement.clientHeight / 2 - centerY,
        },
        { duration: 800 }
      );
    }
  }, [setViewport, nodes, selectedNodeId]);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    setModalData({ personId: null, relation: null });
  }, []);

  const PersonFormWrapper = useCallback(() => {
    if (!modalData.personId) {
      return null;
    }

    const selectedPerson = persons.find(p => p.id.toString() === modalData.personId.toString());

    const formProps = {
      relation: modalData.relation,
      selectedPerson,
      onSubmitSuccess: () => {
        handleCloseModal();
        window.location.reload();
      }
    };

    if (modalData.relation === 'child') {
      if (selectedPerson?.gender === 'male') {
        formProps.parentMaleId = selectedPerson.id;
      } else if (selectedPerson?.gender === 'female') {
        formProps.parentFemaleId = selectedPerson.id;
      }
      formProps.title = 'Добавить ребенка';
    } else if (modalData.relation === 'parent') {
      formProps.childId = selectedPerson?.id;
      formProps.title = 'Добавить родителя';
    } else {
      formProps.editId = selectedPerson?.id;
      formProps.title = 'Редактировать профиль';
    }

    return <div className='border border-gray-400 p-5'><PersonForm {...formProps} /></div>;
  }, [modalData, persons]);

  handleTransform();
  useEffect(() => {
    handleTransform();
  }, [selectedNodeId, handleTransform]);

  const styledEdges = edges.map((edge) => {
    const isEdgeSelected =
      edge.source === selectedNodeId || edge.target === selectedNodeId;
    return {
      ...edge,
      animated: isEdgeSelected,
      style: {
        stroke: isEdgeSelected ? '#333' : '#ddd',
        strokeWidth: isEdgeSelected ? 3 : 2,
      },
    };
  });

  return (
    <>
      <ReactFlow
        nodeTypes={nodeTypes}
        nodes={nodes}
        edges={styledEdges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onNodeClick={onNodeClick}
        connectionLineType={ConnectionLineType.SimpleBezier}
        elevateEdgesOnSelect
        fitView
        nodesDraggable={false}
        style={{ backgroundColor: "#F7F9FB" }}
      >
        <Background />
        <Controls />
      </ReactFlow>

      {/* Модальное окно для добавления нового человека */}
      <BaseModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        FormComponent={PersonFormWrapper}
      />
    </>
  );
};

export default Flow;