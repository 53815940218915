import React, { FC, useState, useEffect } from 'react';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useActions } from '../../../hooks/useActions';
import { useNavigate } from 'react-router-dom';
import { LockClosedIcon, EnvelopeIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid';
import Spinner from '../../common/Spinner';
import Alert from '../../common/Alert';

// Определение стилей анимации shake для CSS
const shakeKeyframes = `
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
`;

const LoginForm: FC = () => {
  const { error, errors, isLoading, isAuth } = useTypedSelector(
    (state) => state.auth,
  );
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [touched, setTouched] = useState({ email: false, password: false });
  const [shake, setShake] = useState(false);
  const { login } = useActions();
  const navigate = useNavigate();

  // Добавляем стили анимации в DOM при монтировании компонента
  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.innerHTML = shakeKeyframes;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  // Проверить, есть ли сохраненные данные
  useEffect(() => {
    const savedUsername = localStorage.getItem('auth_username');
    const savedRememberMe = localStorage.getItem('auth_remember') === 'true';

    if (savedUsername && savedRememberMe) {
      setUsername(savedUsername);
      setRememberMe(savedRememberMe);
    }
  }, []);

  // Эффект для анимации при ошибке
  useEffect(() => {
    if (error || errors.length > 0) {
      setShake(true);
      const timer = setTimeout(() => {
        setShake(false);
      }, 820); // Длительность анимации + небольшая задержка

      return () => {
        clearTimeout(timer);
      };
    }
  }, [error, errors]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Проверка на пустые поля перед отправкой
    if (!username || !password) {
      // Отметить все поля как затронутые, чтобы показать ошибки валидации
      setTouched({ email: true, password: true });
      setShake(true);
      setTimeout(() => setShake(false), 820);
      return;
    }

    // Сохранение данных пользователя, если выбрано "Запомнить меня"
    if (rememberMe) {
      localStorage.setItem('auth_username', username);
      localStorage.setItem('auth_remember', 'true');
    } else {
      // Очистка сохраненных данных, если "Запомнить меня" не выбрано
      localStorage.removeItem('auth_username');
      localStorage.removeItem('auth_remember');
    }

    login(username, password);
  };

  // Валидация полей
  const getEmailError = () => {
    if (!username && touched.email) return 'Укажите адрес электронной почты';
    if (touched.email && username && !/\S+@\S+\.\S+/.test(username))
      return 'Введите корректный адрес электронной почты';
    return '';
  };

  const getPasswordError = () => {
    if (!password && touched.password) return 'Укажите пароль';
    return '';
  };

  useEffect(() => {
    if (isAuth) navigate(`/cabinet/person`);
  }, [isAuth, navigate]);

  // Стиль для анимации встряски
  const shakeStyle = shake ? {
    animation: 'shake 0.82s cubic-bezier(.36,.07,.19,.97) both'
  } : {};

  return (
    <div className="max-w-md w-full mx-auto px-4 sm:px-0">
      <div className="text-center">
        <h1 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-2">
          Добро пожаловать
        </h1>
        <h2 className="text-xl text-gray-600 mb-8">
          Войдите в свой аккаунт
        </h2>
      </div>

      {/* Общая обработка ошибок */}
      <div>
        {error && (
          <Alert
            text={error}
            type="danger"
          />
        )}
        {errors.length !== 0 && (
          <Alert
            text={errors.join(', ')}
            type="danger"
          />
        )}
      </div>

      <div
        className="bg-white rounded-lg shadow-md p-6 sm:p-8"
        style={shakeStyle}
      >
        <form
          className="space-y-6"
          onSubmit={handleSubmit}
          noValidate
        >
          {/* Поле Email */}
          <div>
            <label
              htmlFor="email-address"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Email
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className={`pl-10 block w-full rounded-md border ${getEmailError() ? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500'
                  : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
                  } py-3 shadow-sm sm:text-sm`}
                placeholder="Введите email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onBlur={() => setTouched({ ...touched, email: true })}
              />
              {getEmailError() && (
                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                  <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>
              )}
            </div>
            {getEmailError() && (
              <p className="mt-2 text-sm text-red-600" id="email-error">
                {getEmailError()}
              </p>
            )}
          </div>

          {/* Поле Password */}
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Пароль
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <LockClosedIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className={`pl-10 block w-full rounded-md border ${getPasswordError() ? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500'
                  : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
                  } py-3 shadow-sm sm:text-sm`}
                placeholder="Введите пароль"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onBlur={() => setTouched({ ...touched, password: true })}
              />
              {getPasswordError() && (
                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                  <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>
              )}
            </div>
            {getPasswordError() && (
              <p className="mt-2 text-sm text-red-600" id="password-error">
                {getPasswordError()}
              </p>
            )}
          </div>

          {/* Кнопка входа */}
          <div>
            <button
              type="submit"
              disabled={isLoading}
              className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-3 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-70 disabled:cursor-not-allowed transition duration-150 ease-in-out"
            >
              {isLoading ? (
                <>
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <Spinner color="info" size="xs" />
                  </span>
                  <span className="ml-1">Выполняется вход...</span>
                </>
              ) : 'Войти в аккаунт'}
            </button>
          </div>
        </form>
      </div>

      {/* Блок регистрации */}
      <div className="mt-6 text-center">
        <p className="text-sm text-gray-600">
          Регистрация доступна только для администраторов
        </p>
      </div>
    </div>
  );
};

export default LoginForm;
