import { Handle, Position } from '@xyflow/react';
import moment from 'moment';
import { useState } from 'react';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { UserGroups } from '../../../../types';
import { Link } from 'react-router-dom';

export const nodeTypes = {
  custom: function CustomNode ({ data, selected }) {
    const [showMenu, setShowMenu] = useState(false);

    const { user } = useTypedSelector((state) => state.auth);
    const isManager = UserGroups.manager === user.group;
    const isGuest = UserGroups.guest === user.group;

    const toggleMenu = (e) => {
      e.stopPropagation();
      setShowMenu(!showMenu);
    };

    const handleAddPerson = (relation, e) => {
      e.stopPropagation();
      if (typeof data.onAddPerson === 'function') {
        data.onAddPerson(data.id, relation);
      }
    };

    const handleEditPerson = () => {
      if (typeof data.onEditPerson === 'function') {
        data.onEditPerson(data.id);
      }
    };

    const isManagerAndSelected = isManager && selected;
    const isGuestAndSelected = isGuest && selected;

    return (
      <div className={`px-3 py-2 w-72 shadow-md rounded-md bg-white border-2 relative ${data.gender === 'male' ? 'border-blue-500' : 'border-pink-500'}`}>

        {isManagerAndSelected && (
          <button
            onClick={(e) => handleAddPerson('parent', e)}
            className="absolute -top-8 left-1/2 transform -translate-x-1/2 w-6 h-6 rounded-full bg-gray-100 shadow-md flex items-center justify-center hover:bg-gray-200 transition-colors z-10"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M12 5v14M5 12h14"></path>
            </svg>
          </button>
        )}

        {selected && (
          <button
            onClick={toggleMenu}
            className="absolute top-2 right-2 w-6 h-6 rounded-full bg-gray-100 flex items-center justify-center hover:bg-gray-200 transition-colors"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="currentColor">
              <circle cx="12" cy="6" r="2" />
              <circle cx="12" cy="12" r="2" />
              <circle cx="12" cy="18" r="2" />
            </svg>
          </button>
        )}

        {(showMenu && isManagerAndSelected) && (
          <div className="absolute top-9 right-2 bg-white shadow-lg rounded-md py-1 z-10 w-32">
            <Link
              to={`/profile/${data.id}`}
              className="w-full block text-left px-3 py-2 text-sm hover:bg-gray-100 transition-colors"
            >
              Профиль
            </Link>
            <button
              onClick={handleEditPerson}
              className="w-full block text-left px-3 py-2 text-sm hover:bg-gray-100 transition-colors"
            >
              Редактировать
            </button>
          </div>
        )}

        {showMenu && isGuestAndSelected && (
          <div className="absolute top-9 right-2 bg-white shadow-lg rounded-md py-1 z-10 w-32">
            <Link
              to={`/profile/${data.id}`}
              className="w-full block text-left px-3 py-2 text-sm hover:bg-gray-100 transition-colors"
            >
              Профиль
            </Link>
          </div>
        )}

        <div className="flex">
          <div className="shrink-0 rounded-full w-12 h-12 flex justify-center items-center bg-gray-100 overflow-hidden">
            <img
              className="object-cover h-12 w-12"
              src={
                data.avatar
                  ? `${process.env.REACT_APP_PERSONS_STATIC}${data.avatar}`
                  : `${process.env.REACT_APP_COMMON_STATIC}/placeholder.jpg`
              }
              alt=""
            />
          </div>
          <div className="ml-2 overflow-hidden flex flex-col justify-center gap-0.5">
            <div className="text-md font-bold whitespace-nowrap leading-tight">{data.surname}</div>
            <div className="text-sm whitespace-nowrap leading-tight">{data.givenNames}</div>
            <div className='flex items-center gap-1 text-xs'>
              <div className="text-gray-500 whitespace-nowrap">{moment(data.birthAt).format("YYYY")} г. </div>
              {data.deathAt && <div className="text-gray-500 whitespace-nowrap leading-none"> - {moment(data.deathAt).format("YYYY")} г.</div>}
            </div>
          </div>
        </div>

        {isManagerAndSelected && (
          <button
            onClick={(e) => handleAddPerson('child', e)}
            className="absolute -bottom-8 left-1/2 transform -translate-x-1/2 w-6 h-6 rounded-full bg-gray-100 shadow-md flex items-center justify-center hover:bg-gray-200 transition-colors z-10"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M12 5v14M5 12h14"></path>
            </svg>
          </button>
        )}

        <Handle type="target" position={Position.Top} />
        <Handle type="source" position={Position.Bottom} />
      </div>
    );
  },
};