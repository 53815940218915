import {
  AuthActionEnum,
  SetAuthAction,
  SetErrorAction,
  SetIsLoadingAction,
  SetUserAction,
  SetErrorsAction,
} from './types';
import { IUser } from './../../../models/IUser';
import { AppDispatch } from '../../index';
import AuthService from '../../../api/AuthService';

export const AuthActionCreators = {
  setUser: (user: IUser): SetUserAction => ({
    type: AuthActionEnum.SET_USER,
    payload: user,
  }),
  setIsAuth: (auth: boolean): SetAuthAction => ({
    type: AuthActionEnum.SET_AUTH,
    payload: auth,
  }),
  setIsLoading: (payload: boolean): SetIsLoadingAction => ({
    type: AuthActionEnum.SET_IS_LOADING,
    payload,
  }),
  setError: (payload: string): SetErrorAction => ({
    type: AuthActionEnum.SET_ERROR,
    payload,
  }),
  setErrors: (payload: string[]): SetErrorsAction => ({
    type: AuthActionEnum.SET_ERRORS,
    payload,
  }),
  login:
    (username: string, password: string) => async (dispatch: AppDispatch) => {
      dispatch(AuthActionCreators.setIsLoading(true));
      setTimeout(() => {
        AuthService.login(username, password)
          .then(({ data }) => {
            const { token, userId, username, group } = data;
            localStorage.setItem('token', token);
            localStorage.setItem('userId', userId);
            localStorage.setItem('username', username);
            localStorage.setItem('group', group);
            dispatch(AuthActionCreators.setIsAuth(true));
            dispatch(
              AuthActionCreators.setUser({ userId, username, group } as any),
            );
          })
          .catch(({ response }) => {
            if (response.data.errors) {
              dispatch(AuthActionCreators.setErrors(response.data.errors));
            }
            
            if (response.data.message) {
              dispatch(AuthActionCreators.setError(response.data.message));
            } else {
              dispatch(
                AuthActionCreators.setError('Произошла ошибка при авторизации'),
              );
            }
          })
          .finally(() => {
            dispatch(AuthActionCreators.setIsLoading(false));
          });
      }, 1500);
    },
  logout: () => async (dispatch: AppDispatch) => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('group');
    dispatch(AuthActionCreators.setUser({} as IUser));
    dispatch(AuthActionCreators.setIsAuth(false));
  },

  loginGuest:
    (token: string, userId: string, username: string, group: string) =>
    (dispatch: AppDispatch) => {
      localStorage.setItem('token', token);
      localStorage.setItem('userId', userId);
      localStorage.setItem('username', username);
      localStorage.setItem('group', group);
      dispatch(AuthActionCreators.setIsAuth(true));
      dispatch(AuthActionCreators.setUser({ userId, username, group } as any));
    },
};
