import {
  ReactFlowProvider,
} from '@xyflow/react';
import Flow from './beta-tree/Flow';
import '@xyflow/react/dist/style.css';

function BetaTreeScreen(props) {
  return (
    <ReactFlowProvider>
      <Flow {...props} />
    </ReactFlowProvider>
  );
}

export default BetaTreeScreen;