import Login from './pages/Login';
import CabinetUser from './pages/lc/User';
import CabinetPerson from './pages/lc/person/Index';
import CabinetPhoto from './pages/lc/photo/Index';
import CabinetHistory from './pages/lc/history/Index';
import CabinetHistoryAdd from './pages/lc/history/Add';
import CabinetHistoryEdit from './pages/lc/history/Edit';
import PersonAdd from './pages/lc/person/Add';
import PersonEdit from './pages/lc/person/Edit';
import Cabinet from './pages/lc/Index';
import Tree from './pages/Tree';
import Profile from './pages/profile/Index';
import ProfileHistories from './pages/profile/Histories';
import ProfileHistoriesDetail from './pages/profile/HistoriesDetail';
import ProfilePhotos from './pages/profile/Photos';
import PageNotFound from './pages/404';
import Navbar from './modules/layout/Navbar';
import Footer from './modules/layout/Footer';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Accesses from './pages/lc/Accesses';
import AccessGuest from './pages/AccessGuest';
import PublicHistories from './pages/public/PublicHistories';
import PublicPhotos from './pages/public/PublicPhotos'; 
import BetaTree from './pages/BetaTree';

const Router = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route index element={<Login />} />
        <Route path="histories/" element={<PublicHistories />} />
        <Route path="photos/" element={<PublicPhotos />} />
        <Route path="profile/:id/" element={<Profile />} />
        <Route path="profile/:id/" element={<Profile />} />
        <Route path="profile/:id/histories" element={<ProfileHistories />} />
        <Route
          path="profile/:id/histories/:historyId"
          element={<ProfileHistoriesDetail />}
        />
        <Route path="profile/:id/photos" element={<ProfilePhotos />} />
        <Route path="tree/:id/" element={<Tree />} />
        <Route path="beta-tree/:id/" element={<BetaTree />} />
        <Route path="login" element={<Login />} />
        <Route path="guest/:hash/" element={<AccessGuest />} />
        <Route path="cabinet" element={<Cabinet />} />
        <Route path="cabinet/user" element={<CabinetUser />} />
        <Route path="cabinet/accesses" element={<Accesses />} />
        <Route
          path="cabinet/person-history/:personId"
          element={<CabinetHistory />}
        />
        <Route
          path="cabinet/person-history/:personId/add"
          element={<CabinetHistoryAdd />}
        />
        <Route
          path="cabinet/person-history/:personId/edit/:id"
          element={<CabinetHistoryEdit />}
        />
        <Route
          path="cabinet/person-photo/:personId"
          element={<CabinetPhoto />}
        />
        <Route path="cabinet/person" element={<CabinetPerson />} />
        <Route path="cabinet/person/add" element={<PersonAdd />} />
        <Route path="cabinet/person/edit/:id" element={<PersonEdit />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default Router;
